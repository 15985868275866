import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';


@Component({
  selector: 'upload-data-component',
  templateUrl: './upload-data.component.html',
  styleUrls: ['./upload-data.component.scss']
})

export class UploadDataBlockComponent implements OnInit {
  @Input() upload;
  @Input() index;

  backgroundColor = 'white';
  uploadDate;
  uploadMsg = '-';
  uploadInvMsg = '-';


  constructor() {
  }

  ngOnInit() {
    if (this.index) {
      if (+this.index % 2) {
        this.backgroundColor = '#E8E8E8';
      } else {
        this.backgroundColor = 'white';
      }
    }
    if (this.upload) {
      if (this.upload.createdAt) {
        this.uploadDate = moment(this.upload.createdAt).format('DD.MM.YYYY');
      }
      if (this.upload.msg) {
        const message = 'Bearbeitungshinweis zu Ihrem Auftrag::';
        const comment = 'Rechnungskommentar:';

        if (this.upload.msg.includes(message) || this.upload.msg.includes(comment)) {
          const msg = this.upload.msg.substring(0, this.upload.msg.indexOf(comment));
          this.uploadMsg = msg.slice(msg.indexOf(message) + message.length);
          this.uploadInvMsg = this.upload.msg.slice(this.upload.msg.indexOf(comment) + comment.length);
        } else {
          this.uploadMsg = this.upload.msg;
        }
      }
    }
  }
}
