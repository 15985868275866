import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppRoutingModule} from './app-routing.module';
import {AuthenticationService} from './services/authentication.service';
import {AppConfigService} from './services/app-config.service';
import {ComponentModule} from './component/component.module';
import {ModalService} from './services/modal.service';
import {HttpService} from './services/http.service';
import {HttpErrorInterceptor} from './services/http-error.interceptor';
import {ImprintModule} from './pages/imprint/imprint.module';
import {MetaModule} from '@ngx-meta/core';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    ComponentModule,
    MetaModule.forRoot(),
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    AuthenticationService,
    AppConfigService,
    ModalService,
    HttpService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
