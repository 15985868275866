import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalService} from '../../services/modal.service';

@Component({
  selector: 'modal-dialog',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})

export class ModalComponent implements OnInit {

  @Output() doLogout = new EventEmitter();

  constructor(public modalService: ModalService) {

  }

  ngOnInit() {
    this.modalService.openModal.subscribe((res: any) => {
      if (res) {
        if (res === 9 || res === 7) {
          const modal = document.getElementById(res === 9 ? 'modal1' : 'modal2');
          if (modal) {
            modal.style.display = 'block';
          }
        } else {
          const modal = document.getElementById('modal');
          if (modal) {
            modal.style.display = 'block';
          }
        }
      } else {
        const modal = document.getElementById('modal');
        const modal1 = document.getElementById('modal1');
        const modal2 = document.getElementById('modal2');
        if (modal) {
          modal.style.display = 'none';
        }
        if (modal1) {
          modal1.style.display = 'none';
        }
        if (modal2) {
          modal2.style.display = 'none';
        }
      }
    });
  }

  closeModal() {
    this.modalService.openModal.next(0);
  }

  logout() {
    this.closeModal();
    this.doLogout.emit(true);
  }
}
