import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'footer-component',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  currentYear;

  constructor(private router: Router) {
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit() {
  }

  navigateTo(url) {
    this.router.navigate([url]);
  }
}
