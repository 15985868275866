import {Component, ElementRef, HostListener} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from './services/authentication.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AppConfigService} from './services/app-config.service';
import {ModalService} from './services/modal.service';
import {MetaService} from '@ngx-meta/core';
import {Action} from 'rxjs/internal/scheduler/Action';
import {Location} from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'threeW';
  user;
  showMenu = false;
  headerData: any;
  showMobileMenu = false;
  state;
  userId;
  pageTitle;
  url;
  showSubmenu = false;
  language = 'de';

  constructor(public translate: TranslateService, private authenticationService: AuthenticationService,
              private router: Router,  private config: AppConfigService, private modalService: ModalService,
              private readonly meta: MetaService, private location: Location,
              private elRef: ElementRef) {
    this.translate.addLangs(['en', 'de']);
    this.config.langItem.subscribe((res: any) => {
      if (res) {
        this.language = res;
        this.setLang();
      }
    });
    this.initializeApp();
    this.url = this.location.path();
    if ((this.url.indexOf('cid=') !== -1) && (this.url.indexOf('email=') !== -1)) {
      if (this.url.indexOf('cid=') < this.url.indexOf('email=')) {
        const data = {
          cid: this.url.slice(this.url.indexOf('cid=') + 4, this.url.indexOf('email=') - 1),
          email: decodeURIComponent(this.url.slice(this.url.indexOf('email=') + 6, this.url.length)),
        };
        this.config.loginPrefield.next(data);
      } else {
        const data = {
          cid: this.url.slice(this.url.indexOf('cid=') + 4, this.url.length),
          email: decodeURIComponent(this.url.slice(this.url.indexOf('email=') + 6, this.url.indexOf('cid=') - 1)),
        };
        this.config.loginPrefield.next(data);
      }
    }
    this.router.events.subscribe((res: any) => {
      if (res instanceof NavigationEnd) {
        if (res && res.url) {
          let pageTitle;
          if (res.url.indexOf('dashboard') !== -1) {
            pageTitle = 'Dashboard';
            this.meta.setTitle('Dashboard - 3W Freistellen');
          } else if (res.url.indexOf('assignment') !== -1) {
            pageTitle = 'Assignments';
            this.meta.setTitle('Neue Bestellung - 3W Freistellen');
          } else if (res.url.indexOf('upload') !== -1) {
            pageTitle = 'Upload_data';
            this.meta.setTitle('Meine Daten - 3W Freistellen');
          } else if (res.url.indexOf('bills') !== -1) {
            pageTitle = 'Bills';
            this.meta.setTitle('Rechnungen - 3W Freistellen');
          } else if (res.url.indexOf('myData') !== -1) {
            pageTitle = 'My_data';
            this.meta.setTitle('Meine Daten - 3W Freistellen');
          } else if (res.url.indexOf('impressum') !== -1) {
            pageTitle = 'Information';
            this.meta.setTitle('Information - 3W Freistellen');
          } else if (res.url.indexOf('privacy') !== -1) {
            pageTitle = 'Information';
            this.meta.setTitle('Information - 3W Freistellen');
          } else {
            this.meta.setTitle('3W Freistellen');
          }
          if (res.url.indexOf('login') === -1) {
            this.pageTitle = pageTitle;
            this.headerData = {
              pageTitle: pageTitle,
              user: {
                name: 'Peter Keller',
                id: 'user_001744',
                // image: 'https://www.udc.ch/wp-content/uploads/sites/2/Keller_Peter-720x720.jpg'
              }
            };
          }
        }
      }
    });
  }

  @HostListener('document:click', ['$event.target'])
  onDocumentClick(target): void {
    if (this.showSubmenu) {
      if (!this.checkTarget(target)) {
        this.showSubmenu = false;
      } else {

      }
    }
  }

  checkTarget(target) {
    return target.className.includes('submenu')
      || target.className.includes('title')
      || target.className.includes('sub-list')
      || target.className.includes('list-item')
      || target.className.includes('description')
      || target.className.includes('text');
  }

  downloadZip() {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.setAttribute('target', '_blank');
    link.href = 'assets/plugin/freistellen.de_ftp_upload.lrplugin.zip';
    link.download = 'freistellen.de_ftp_upload.lrplugin.zip';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  setLang() {
    this.translate.setDefaultLang(this.language);
    this.translate.use(this.language);
    moment.locale(this.language);
  }

  initializeApp() {
    this.authenticationService.authenticationState.subscribe(state => {
      this.state = state;
      if (+state === 1) {
        this.showMenu = true;
        const user: any = localStorage.getItem('user');
        this.userId = localStorage.getItem('user_id');
        if (user) {
          this.user = JSON.parse(user);
        } else {
          this.showMenu = false;
          this.modalService.loadingSymbol.next(1);
          this.authenticationService.getProfile().subscribe((response: any) => {
            localStorage.setItem('user', JSON.stringify(response));
            this.user = response;
            this.router.navigate(['pages/dashboard']);
            this.modalService.loadingSymbol.next(0);
          }, (e) => {
            this.showMenu = false;
            this.router.navigate(['/login']);
            this.modalService.loadingSymbol.next(0);
          });
        }
      } else if (+state === 0 || +state === -1) {
        this.showMenu = false;
        this.showMobileMenu = false;
        this.router.navigate(['login']);
        localStorage.removeItem('user');
        localStorage.removeItem('access-token');
        this.user = null;
      }
    });
  }

  public doLogout(e) {
    this.modalService.openModal.next(1);
    // if (e = 'logout') {
    //   this.config.ssid.next('');
    //   localStorage.removeItem('ssid');
    //   localStorage.removeItem('user');
    //   this.authenticationService.authenticationState.next(0);
    //   this.headerData = '';
    // }
  }

  logout(e) {
    if (e) {
        this.config.ssid.next('');
        localStorage.removeItem('ssid');
        localStorage.removeItem('user');
        localStorage.removeItem('access-token');
        this.authenticationService.authenticationState.next(0);
        this.user = null;
    }
  }

  openLink() {
    const links = {
      en: 'https://www.freistellen.de/en/questions-and-answers/',
      de: 'https://www.freistellen.de/fragen-und-antworten/'
    };
    const el = document.createElement('a');
    el.setAttribute('type', 'hidden');
    el.setAttribute('target', '_blank');
    el.href = links[this.language];
    document.body.appendChild(el);
    el.click();
  }
}
