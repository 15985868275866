import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';
import {AuthenticationService} from '../../services/authentication.service';
import {ModalService} from '../../services/modal.service';

@Component({
  selector: 'assignment-component',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.scss']
})

export class AssignmentBlockComponent implements OnInit {

  @Input() assign;
  @Input() index;

  backgroundColor = 'white';
  created = '-';
  finished = '-';
  downloads = '';
  formats: any = '';
  size = '';
  loading = false;
  showIcon = false;
  colorBorder = '';
  titleForColor;
  price = '-';
  total_price = '-';
  comment = '-';
  constructor(private authService: AuthenticationService, private modalService: ModalService) {

  }

  ngOnInit() {
    if (this.index) {
      if (+this.index % 2) {
        this.backgroundColor = '#E8E8E8';
      } else {
        this.backgroundColor = 'white';
      }
    }
    if (this.assign) {
      if (this.assign.created) {
        this.created = moment(this.assign.created).format('DD.MM.YYYY');
      }
      if (this.assign.finished) {
        this.finished = moment(this.assign.finished).format('DD.MM.YYYY');
        this.finished = this.finished + '\n' + moment(this.assign.finished).format('HH:mm') + ' Uhr';
      }
      this.downloads = this.assign.downloads + '';
      while (this.downloads.length < 3) {
        this.downloads = '0' + this.downloads;
      }
      if (this.assign.formats && this.assign.formats.length) {
        for (let i = 0; i < this.assign.formats.length; i++) {
          this.formats = this.formats + this.assign.formats[i] + ', ';
        }
        this.formats = this.formats.slice(0, -2);
      }
      if (this.assign.size) {
        if (this.assign.size > 1000000000) {
          this.size = (this.assign.size / 1000000000).toFixed(1) + ' GB';
        } else if (this.assign.size < 1000000000 && this.assign.size > 1000000) {
          this.size = (this.assign.size / 1000000).toFixed(1) + ' MB';
        } else if (this.assign.size < 1000000 && this.assign.size > 1000) {
          this.size = (this.assign.size / 1000).toFixed(1) + ' KB';
        } else {
          this.size = this.assign.size + ' Byte';
        }
        this.size = this.size.replace('.', ',');
      }
      if (!this.assign.zip_filename ||
        moment().isAfter(moment(this.assign.finished).add(7, 'days'))) {
        this.showIcon = false;
      } else {
        this.showIcon = true;
      }
      if (this.assign.status_color) {
        this.colorBorder = this.assign.status_color;
        if (this.colorBorder === '#FF3300') {
          this.titleForColor = 'Angelegt';
        } else if (this.colorBorder === '#FFFF00') {
          this.titleForColor = 'Korrektur';
        } else if (this.colorBorder === '#0000FF') {
          this.titleForColor = 'Bearbeitung';
        } else if (this.colorBorder === '#009900') {
          this.titleForColor = 'In Prüfung / Fertiggestellt';
        }
      } else {
        this.colorBorder = '#F68836';
      }
      if (this.assign.price.price || (this.assign.price.price === 0)) {
        if (Number.isInteger(this.assign.price.price)) {
          this.price = this.assign.price.price;
        } else {
          this.price = this.assign.price.price.toFixed(2);
          this.price = this.price.replace('.', ',');
        }
      }
      if (this.assign.total_price.price || (this.assign.total_price.price === 0)) {
        if (Number.isInteger(this.assign.total_price.price)) {
          this.total_price = this.assign.total_price.price;
        } else {
          this.total_price = this.assign.total_price.price.toFixed(2);
          this.total_price = this.total_price.replace('.', ',');
        }
      }
      if (this.assign.invoice_comment) {
        this.comment = this.assign.invoice_comment;
      }
    }
  }

  download(id, filename) {
    this.loading = true;
    this.modalService.loadingSymbol.next(1);
    this.authService.getDownloadLink(id).subscribe(
      (res: any) => {
        this.loading = false;
        const blob = new Blob([res], {type: 'application/octet-stream'});
        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('type', 'hidden');
        link.href = downloadURL;
        link.download = filename;
        document.body.appendChild(link);
        this.modalService.loadingSymbol.next(0);
        link.click();
        link.remove();
        // let a = document.createElement('a');
        // a.setAttribute('href', downloadURL);
        // a.setAttribute('target', '_blank');
        // a.click();
        // a.remove();
      }, (e) => {
        this.loading = false;
        this.modalService.loadingSymbol.next(0);
      });
  }
}
