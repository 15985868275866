import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../../services/authentication.service';
import {DomSanitizer} from '@angular/platform-browser';


@Component({
  selector: 'bill-block',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.scss']
})

export class BillComponent implements OnInit {

  @Input() bill;
  @Output() action = new EventEmitter();

  backgroundColor = 'white';
  dateIn;
  dateOut;
  dateEmail;
  color;
  paymentsData: any = {};
  paymentsTypes;

  constructor(private authService: AuthenticationService, private domSanitizier: DomSanitizer) {

  }

  ngOnInit() {
    if (this.bill) {
      if (this.bill.payment) {
        this.backgroundColor = 'white';
      } else {
        this.backgroundColor = '#E8E8E8';
      }
      if (this.bill.document_date) {
        this.dateIn = moment(this.bill.document_date, 'YYYY-MM-DD').format('DD.MM.YYYY');
      }
      if (this.bill.due_date) {
        this.dateOut = moment(this.bill.due_date, 'YYYY-MM-DD').format('DD.MM.YYYY');
      }
      if (this.bill.email_send_date) {
        this.dateEmail = moment(this.bill.email_send_date, 'YYYY-MM-DD').format('DD.MM.YYYY');
      }
      if (this.bill.ref_id) {
        this.color = '#E8E8E8';
      } else if (this.bill.amount_paid === this.bill.amount) {
        this.color = '#2DC0AE';
      } else if (this.bill.cancelled && (this.bill.ref_id === null)) {
        this.color = '#E93C3E';
      } else if (this.bill.payments.length) {
          this.color = '#009900';
      } else if (new Date() > new Date(this.bill.due_date)) {
        this.color = '#E93C3E';
      } else {
        this.color = '#E8E8E8';
      }
      if (this.bill.payments) {
        this.bill.payments.forEach((item) => {
          if (this.paymentsData) {
            if (this.paymentsData.hasOwnProperty(item.type)) {
              this.paymentsData[item.type].push({
                date: item.payment_at,
                amount: item.amount
              });
            } else {
              this.paymentsData[item.type] = [{
                date: item.payment_at,
                amount: item.amount
              }];
            }
          } else {
            this.paymentsData[item.type] = [{
              date: item.payment_at,
              amount: item.amount
            }];
          }
        });
        if (this.paymentsData) {
          this.paymentsTypes = Object.keys(this.paymentsData);
        }
      }
    }
  }

  changeShow() {
    this.bill.openDetail = !this.bill.openDetail;
    this.action.emit(this.bill.id);
  }

  transformDate(date) {
    return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
  }

  downloadPDF(id, num = '', refId = '') {
    document.body.style.cursor = 'progress';
    this.authService.getPdfV2(id).subscribe((res) => {
      this.downloadFile(res, num, refId);
    }, (e) => {document.body.style.cursor = 'auto'; } );
  }

  downloadFile(data, num, refId) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    if (refId) {
      a.download = 'Rechnung-' + refId + '.pdf';
    } else {
      a.download = 'Rechnung-' + num + '.pdf';
    }
    a.target = '_blank';
    a.click();
    a.remove();
    document.body.style.cursor = 'auto';
  }
}
