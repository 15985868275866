// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  baseUrl: 'https://login.freistellen.de/api/v1/',
  baseUrlV2: 'https://login.freistellen.de/api/v2/',
  safeboxUrl: 'https://freistellen.3wsafebox.com/de/',
  safeboxKkUrlEN: 'https://freistellen.3wsafebox.com/en/kk-integration',
  safeboxKkUrlDE: 'https://freistellen.3wsafebox.com/de/kk-integration',
  safeboxUrlTus: 'https://freistellen.3wsafebox.com/tus/'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
