import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {HeaderComponent} from './header/header.component';
import {CommonModule} from '@angular/common';
import {FooterComponent} from './footer/footer.component';
import {PageBlockComponent} from './pageBlock/pageBlock.component';
import {RouterModule} from '@angular/router';
import {AssignmentBlockComponent} from './assignment/assignment.component';
import {UploadDataBlockComponent} from './upload-data/upload-data.component';
import {BillComponent} from './billsBlock/bill.component';
import {ModalComponent} from './modal/modal.component';
import {LoadingComponent} from './loading/loading.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    TranslateModule.forChild()
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    PageBlockComponent,
    AssignmentBlockComponent,
    UploadDataBlockComponent,
    BillComponent,
    ModalComponent,
    LoadingComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    PageBlockComponent,
    AssignmentBlockComponent,
    UploadDataBlockComponent,
    BillComponent,
    ModalComponent,
    LoadingComponent
  ]
})

export class ComponentModule {}
