import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';

const routes: Routes = [
  {path: '', redirectTo: 'pages/dashboard', pathMatch: 'full'},
  {path: 'login', loadChildren: './public/login/login.module#LoginPageModule'},
  {
    path: 'pages',
    canActivate: [AuthGuard],
    loadChildren: './pages/routing.module#RoutingModule'
  },
  { path: 'dashboard', loadChildren: './pages/dashboard/dashboard.module#DashboardModule' },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
