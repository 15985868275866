import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {AppConfigService} from '../../services/app-config.service';

@Component({
  selector: 'header-component',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnChanges {
  @Input() headerData;
  @Input() state;
  @Input() pageTitle;
  @Input() userId;
  @Input() displayMenu = false;
  @Input() appComponent;
  @Output() action = new EventEmitter();
  @Output() logout = new EventEmitter();

  showLangDialog = false;
  availableLanguages = [
    {url: 'german.png', name: 'Deutsch', id: 'de'},
    {url: 'english.png', name: 'English', id: 'en'}
  ];
  currentLang = {url: 'german.png', name: 'Deutsch', id: 'de'};

  constructor(private config: AppConfigService) {
    this.config.langItem.subscribe((res: any) => {
      this.availableLanguages.forEach((item) => {
        if (item.id === res) {
          this.currentLang = item;
        }
      });
    });
  }

  ngOnChanges() {
    if (this.appComponent) {
      this.appComponent.addEventListener('click', () => {
        this.showLangDialog = false;
      });
    }
  }

  showMenu() {
    this.displayMenu = !this.displayMenu;
    this.action.emit(this.displayMenu);
  }

  onOpenLangDialog(event: any): void {
    event.stopPropagation();
    this.showLangDialog = true;
  }

  setLanguage(item: any): void {
    this.config.setLang(item.id);
  }

  public doLogout() {
    this.logout.emit('logout');
  }
}
