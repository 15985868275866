import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';
import {Injectable} from '@angular/core';

@Injectable()

export class HttpErrorInterceptor implements HttpInterceptor {
  constructor (private authService: AuthenticationService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.authService.authenticationState.next(-1);
          }
          return throwError(error);
        })
      );
  }
}
