import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {a, st} from '@angular/core/src/render3';

@Injectable()
export class AppConfigService {
  public baseUrl: string;
  public headers: any;

  ssid = new BehaviorSubject('');
  loginPrefield = new BehaviorSubject(null);
  langItem = new BehaviorSubject('');
  acceptedLang = ['en', 'de'];

  constructor() {

    const ssidStorage: any = localStorage.getItem('access-token');
    if (ssidStorage) {
      this.ssid.next(ssidStorage);
    }
    const browserLangs: string[] = navigator.languages;
    const ssidLang: any = localStorage.getItem('user-lang');
    if (ssidLang) {
      this.langItem.next(ssidLang);
    } else {
      let browserLang;
      for (let i = 0; i < browserLangs.length; i++) {
        if (this.acceptedLang.includes(browserLangs[i])) {
          browserLang = browserLangs[i];
          break;
        }
      }
      if (browserLang && (this.acceptedLang.includes(browserLang))) {
        this.langItem.next(browserLang);
        localStorage.setItem('user-lang', browserLang);
      } else {
        this.langItem.next('de');
        localStorage.setItem('user-lang', 'de');
      }
    }
    // this.headers = {
    //   headers: {
    //     'Content-type': 'application/json'
    //   }
    // };
    // this.ssid.subscribe(res => {
    //   const _ssid = res;
    //   if (_ssid) {
    //     this.headers.headers['Authorization'] = `Bearer ${_ssid}`;
    //   } else {
    //     delete this.headers.headers['Authorization'];
    //   }
    // });
  }

  setLang(lang) {
    this.langItem.next(lang);
    localStorage.setItem('user-lang', lang);
  }
}
