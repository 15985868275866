import {Component, Input} from '@angular/core';

@Component({
  selector: 'page-block',
  templateUrl: './pageBlock.component.html',
  styleUrls: ['./pageBlock.component.scss']
})

export class PageBlockComponent {

  @Input() data;

  constructor() {

  }
}
