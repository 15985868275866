import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import { HttpClient} from '@angular/common/http';
import {HttpService} from './http.service';


@Injectable()
export class AuthenticationService {
    authenticationState = new BehaviorSubject(-1);
    username;
    customerId;

    constructor(private http: HttpClient, private httpService: HttpService) {
        if (localStorage.getItem('access-token')) {
          this.authenticationState.next(1);
        } else {
          this.authenticationState.next(0);
        }
    }

    login(username: string, customer_id: string) {
        this.username = username;
        this.customerId = customer_id;
        return this.httpService.post('token', JSON.stringify({
          username, customer_id
        }));
    }

    getList(page = 1, perPage = 10) {
      return this.httpService.get(`order?page=${page}&perPage=${perPage}`);
    }

    getPayment(page = 1, perPage = 10) {
      return this.httpService.get(`payment?page=${page}&perPage=${perPage}`);
    }

    getPaymentV2(page = 1, perPage = 10) {
      return this.httpService.getV2(`payment?page=${page}&perPage=${perPage}`);
    }

    getProfile() {
      return this.httpService.get(`profile`);
    }

    getPdf(id) {
      return this.httpService.getPdf(`payment/${id}/pdf`);
    }

    getPdfV2(id) {
      return this.httpService.getPdfV2(`payment/${id}/pdf`);
    }

    getNews(page = 1, perPage = 10) {
      return this.httpService.get(`news?page=${page}&perPage=${perPage}`);
    }

    getLastLogin() {
      return this.httpService.get(`last-login`);
    }

    getOrderStatistic() {
      return this.httpService.get(`order/statistic`);
    }

    getSystemStatus() {
      return this.httpService.get(`system-stats/all`);
    }

    forgotPasword(email) {
      return this.httpService.post(`forgot-password/${email}`, {email: email});
    }

    putDataProfile(data) {
      return this.httpService.put(`profile`, data);
    }

    getDownloadLink(id) {
      return this.httpService.getPdf(`order/${id}/download`);
    }

    isAuthenticated() {
        return this.authenticationState.value === 1;
    }

    uploadData(data) {
        return this.httpService.upload('uploads', data);
    }

    completeUploadData(data) {
      return this.httpService.complete('uploads_complete', data);
    }

    cancelUploadData(data) {
      return this.httpService.cancel('uploads_cancel', data);
    }

    uploadDataUppy(data) {
      return this.httpService.uploadUppy('uploads_uppy', data);
    }

    cancelUploadDataUppy(data) {
      return this.httpService.cancelUploadUppy('uploads', data);
    }

    getUploadLink(uploadToken) {
      return this.httpService.getLink(`uploads_link/${uploadToken}`);
    }

    getLoginPage() {
      return this.httpService.getLogin();
    }

    onLogin(token) {
      return this.httpService.onLogin('login_check', token);
    }

    getUploadsList(data) {
      return this.httpService.safeboxPost('user-uploads', data);
    }
}
