import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {environment} from '../../environments/environment';


@Injectable()
export class HttpService {
  user;
  baseUrl = environment.baseUrl;
  baseUrlV2 = environment.baseUrlV2;
  safeboxUrl = environment.safeboxUrl;
  safeboxUrlTus = environment.safeboxUrlTus;
  options = {
    headers : {
      'Content-type': 'application/json'
    }
  };

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.appConfig.ssid.subscribe( res => {
      const _ssid = res;
      this.user = {
        email: localStorage.user_email,
        pass: localStorage.user_id
      };
      if (_ssid) {
        this.options.headers['Authorization'] = `Bearer ${_ssid}`;
      } else {
        delete this.options.headers['Authorization'];
      }
    });
  }

  get(url) {
    return this.http.get(this.baseUrl + url, this.options);
  }

  getPdf(url) {
    return this.http.get(this.baseUrl + url, {headers: this.options.headers, responseType: 'blob'});
  }

  getPdfV2(url) {
    return this.http.get(this.baseUrlV2 + url, {headers: this.options.headers, responseType: 'blob'});
  }

  post(url, data) {
    return this.http.post(this.baseUrl + url, data, this.options);
  }

  safeboxPost(url, data) {
    data.email = this.user.email;
    data.clientId = this.user.pass;
    return this.http.post(this.safeboxUrl + 'api/v1/' + url, data, this.options);
  }

  put(url, data) {
    return this.http.put(this.baseUrl +  url, data,  this.options);
  }

  getV2(url) {
    return this.http.get(this.baseUrlV2 + url, this.options);
  }

  upload(url, data) {
    return this.http.post(this.safeboxUrl + 'api/v1/' + url, data, { headers: this.options.headers, withCredentials: true });
  }

  complete(url, data) {
    const params = new HttpParams()
      .set('uploadToken', data.uploadToken)
      .set('disposition', data.disposition)
      .set('totalSize', data.totalSize)
      .set('filesList', data.filesList);

    return this.http.post(this.safeboxUrlTus + url, params, {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      withCredentials: true
    });
  }

  cancel(url, data) {
    const params = new HttpParams()
      .set('uploadToken', data.uploadToken)
      .set('disposition', data.disposition)
      .set('totalSize', data.totalSize)
      .set('filesList', data.filesList);

    return this.http.post(this.safeboxUrlTus + url, params, {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      withCredentials: true
    });
  }

  uploadUppy(url, data) {
    return this.http.post(this.safeboxUrl + 'api/v1/' + url, data, {
      headers: this.options.headers,
      withCredentials: true
    });
  }

  cancelUploadUppy(url, data) {
    return this.http.patch(this.safeboxUrl + 'api/v1/' + `${url}/${data}/cancel`, {}, {
      headers: this.options.headers,
      withCredentials: true
    });
  }

  getLink(url) {
    return this.http.get(this.safeboxUrl + 'api/v1/' + url, {
      headers: this.options.headers,
      withCredentials: true
    });
  }

  getLogin() {
    return this.http.get(`${this.safeboxUrl}`, {withCredentials: true, responseType: 'text'});
  }

  onLogin(url, token) {
    const params = new HttpParams()
      .set('_target_path', this.safeboxUrl)
      .set('_csrf_token', token)
      .set('_username', this.user.email)
      .set('_password', this.user.pass)
      .set('_submit', '');

    return this.http.post(`${this.safeboxUrl}${url}`, params, {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      withCredentials: true,
      responseType: 'text',
      observe: 'response'
    });
  }
}
