import {Component, OnInit} from '@angular/core';
import {ModalService} from '../../services/modal.service';

@Component({
  selector: 'loading-page',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})

export class LoadingComponent implements OnInit {

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    this.modalService.loadingSymbol.subscribe((res) => {
      if (res) {
        const el = document.getElementById('loadingPageMain');
        el.style.display = 'flex';
      } else {
        const el = document.getElementById('loadingPageMain');
        el.style.display = 'none';
      }
    });
  }
}
